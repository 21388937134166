import { useSelectList } from './hooks';
import { Props } from './interfaces';

import * as S from './FormSelect.styled';

const FormSelect = (formInput: Props) => {
  const {
    formSelectProps: { label, errorMessage, options, withCheck },
    restProps,
    focused,
    handleFocusedBlur,
    handleFocusedFocus,
  } = useSelectList(formInput);

  return (
    <S.FormSelect className={restProps.className} style={restProps.style}>
      {label ? <S.Label htmlFor={restProps.id}>{label}</S.Label> : null}

      <S.Select
        {...restProps}
        onBlur={handleFocusedBlur}
        onFocus={handleFocusedFocus}
        data-focused={errorMessage && focused ? true : false}
        data-pass={!errorMessage && restProps.value ? true : false}
      >
        {options.map((option, i) => (
          <S.Option value={option.value} key={i}>
            {option.label}
          </S.Option>
        ))}
      </S.Select>

      {!errorMessage && restProps.value ? true : false}

      {withCheck ? (
        <S.IsCheck
          top={label ? 'true' : 'false'}
          pass={!errorMessage && restProps.value ? 'true' : 'false'}
        />
      ) : null}
      <S.Span>{errorMessage}</S.Span>
    </S.FormSelect>
  );
};

export default FormSelect;
