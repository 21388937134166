import styled from 'styled-components';
import { ButtonProps } from 'react-html-props';

interface RegularButtonProps extends ButtonProps {
  color: 'blue' | 'orange' | 'grey';
  fullWidth: boolean;
}

export const RegularButton = styled.button.attrs((props: RegularButtonProps) => {
  return { ...props };
})`
  border: none;
  padding: 12px 15px;
  border-radius: 12px;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  min-width: 115px;
  background-color: ${({ color }) =>
    color === 'blue'
      ? 'var(--color-mid-blue)'
      : color === 'orange'
      ? 'var(--color-orange-red)'
      : color === 'grey'
      ? 'var(--color-dark-blue-cancel)'
      : 'transparent'};
  color: ${({ color }) =>
    color === 'blue'
      ? 'var(--color-soft-white)'
      : color === 'orange'
      ? 'white'
      : color === 'grey'
      ? 'var(--color-soft-white)'
      : 'black'};
  font-size: 14px;
  font-family: inherit;
  font-weight: 400;
  transition: var(--transition);
  cursor: pointer;

  &:hover {
    background-color: ${({ color }) =>
      color === 'blue'
        ? 'var(--color-light-blue)'
        : color === 'orange'
        ? 'var(--color-orange)'
        : color === 'grey'
        ? 'var(--color-greyish-black)'
        : 'transparent'};
  }
`;
