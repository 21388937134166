import styled, { css } from 'styled-components';
import { BsCheckCircleFill } from 'react-icons/bs';
import { InputProps } from 'react-html-props';

export const FormInput = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;

  &[data-ischeckbox='true'] {
    flex-direction: row-reverse;
    justify-content: flex-end;

    & label {
      font-size: 14px;
      cursor: pointer;
      font-weight: 500;
    }
  }
`;

export const Input = styled.input.attrs((props: InputProps) => {
  return { ...props };
})`
  padding: 12px 20px;
  margin: 0px 0px 25px 0px;
  border-radius: 12px;
  background-color: var(--color-soft-purple);
  border: 1px solid transparent;
  outline: none;
  font-size: 16px;
  color: var(--color-soft-white);
  transition: var(--transition);
  color-scheme: dark;

  &:focus {
    border: 1px solid var(--color-mid-blue);
    background-color: var(--color-greyish-black);
  }

  &:active {
    background-color: var(--color-purple);
  }

  &[disabled] {
    background-color: var(--color-grey);
    color: #625e5e;
    cursor: not-allowed;
  }

  &[data-focused='true'] ~ span {
    display: block;
  }

  &[data-focused='true'] {
    border: 1px solid #ef4848;
  }

  &[data-pass='true'] {
    border: 1px solid #3bb94e;
  }

  &[type='checkbox'] {
    margin-right: 10px;
    cursor: pointer;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: var(--color-soft-white);
    box-shadow: 0 0 0px 1000px var(--color-soft-black) inset;
    -webkit-box-shadow: 0 0 0px 1000px var(--color-soft-black) inset;
  }

  &:-webkit-autofill:focus {
    box-shadow: 0 0 0px 1000px var(--color-soft-black) inset;
    -webkit-box-shadow: 0 0 0px 1000px var(--color-greyish-black) inset;
  }
`;

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
  color: var(--color-soft-white);
`;

export const Span = styled.span`
  position: absolute;
  font-size: 16px;
  padding: 3px;
  color: #ef4848;
  /* display: none; */
  bottom: -2px;
`;

interface IsCheckProps {
  pass: 'true' | 'false';
  top?: 'true' | 'false';
}

export const IsCheck = styled(BsCheckCircleFill)<IsCheckProps>`
  position: absolute;
  padding-top: 1px;
  top: ${({ top }) => (top === 'true' ? '50%' : '33%')};
  right: 10px;
  font-size: 18px;
  transform: translate(-50%, -50%);
  color: #3bb94e;
  transition: var(--transition);
  ${({ pass }) =>
    pass === 'true'
      ? css`
          opacity: 1;
        `
      : css`
          opacity: 0;
        `}
`;
