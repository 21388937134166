import { ikusaName } from 'infrastructure/assets';

import * as S from './Footer.styled';

const Footer = () => {
  return (
    <S.Footer>
      <S.Footer__leftCont>
        <S.Footer__logo src={ikusaName} alt='ikusa media' />
        <a href='mailto:info@ikusamedia.com'>
          <S.TextInfo>info@ikusamedia.com</S.TextInfo>
        </a>
        <S.Footer__socialLinks>
          <S.Footer__socialLink
            href='https://www.instagram.com/ikusamediainc/'
            target='_blank'
            rel='noreferrer'
          >
            <S.Instagramlogo /> <S.TextInfo inSocial>Ikusamediainc</S.TextInfo>
          </S.Footer__socialLink>

          <S.Footer__socialLink
            href='https://www.facebook.com/ikusamedia/'
            target='_blank'
            rel='noreferrer'
          >
            <S.Facebooklogo /> <S.TextInfo inSocial>Ikusa Media</S.TextInfo>
          </S.Footer__socialLink>
        </S.Footer__socialLinks>

        <S.Footer__socialLink>
          <S.Copyright /> <S.TextInfo>All rights reserved.</S.TextInfo>
        </S.Footer__socialLink>
      </S.Footer__leftCont>
      <S.Footer__rightCont></S.Footer__rightCont>
    </S.Footer>
  );
};

export default Footer;
