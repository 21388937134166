export const staffAmount = [
  {
    value: '',
    label: '',
  },
  {
    value: 'equal or less than 10',
    label: 'equal or less than 10',
  },
  {
    value: 'equal or less than 50',
    label: 'equal or less than 50',
  },
  {
    value: 'equal or less than 100',
    label: 'equal or less than 100',
  },
  {
    value: 'equal or less than 200',
    label: 'equal or less than 200',
  },
  {
    value: 'equal or bigger than 300',
    label: 'equal or bigger than 300',
  },
];
