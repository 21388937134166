import styled from 'styled-components';

export const Header = styled.header`
  grid-area: header;
  z-index: 100;
  width: 100%;
  height: 60px;
  background-color: #15131d;
`;

export const Header__InnerCont = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  padding: 0px 30px 0 30px;

  @media screen and (min-width: 600px) {
    padding: 0px 40px 0 40px;
  }

  @media screen and (min-width: 900px) {
    padding: 0px 70px 0 70px;
  }

  @media screen and (min-width: 1200px) {
    padding: 0px 102px 0 102px;
  }
`;

export const BriefTitle = styled.span`
  color: #fafafa;
  font-size: 14px;

  @media screen and (min-width: 600px) {
    font-size: 16px;
  }

  @media screen and (min-width: 900px) {
    font-size: 18px;
  }

  @media screen and (min-width: 1200px) {
    font-size: 20px;
  }
`;
