import { FormValueProps, FormErrorsProps } from '../interfaces';

export const formValues: FormValueProps = {
  //1
  name: '',
  companyStartDate: '',
  companyEmail: '',
  phoneNumber: '',
  //2
  address1: '',
  address2: '',
  city: '',
  state: '',
  zipCode: '',
  country: '',
  //3
  staffAmount: '',
  briefCompanyHistory: '',
  missionVision: '',
  industrySector: '',
  productOrService: '',
  //4
  currentPositionInMarket: '',
  targetAudience: '',
  clientGeoLocation: '',
  //available brand assets
  availableAssetLogo: '',
  availableAssetBrandbook: '',
  availableAssetFonts: '',
  availableAssetColorPalettesInput: '',
  availableAssetmarketingCollateralInput: '',
  availableAssetBrochureInput: '',
  //5
  //competitors
  competitors1: '',
  competitors2: '',
  competitors3: '',
  //websites
  website1: '',
  website2: '',
  website3: '',
  usp: '',
  //6
  likeOrDislike: '',
  designAndFunction: '',
  pagesInWebsite: '',
  trafficMarketingOptions: '',
  //7
  landOnWebsite: '',
  howSuccess: '',
  aditionalInfo: '',
};

export const formErrors: FormErrorsProps = {
  //1
  name: '',
  companyStartDate: '',
  companyEmail: '',
  phoneNumber: '',
  //2
  address1: '',
  address2: '',
  city: '',
  state: '',
  zipCode: '',
  country: '',
  //3
  staffAmount: '',
  briefCompanyHistory: '',
  missionVision: '',
  industrySector: '',
  productOrService: '',
  //4
  currentPositionInMarket: '',
  targetAudience: '',
  clientGeoLocation: '',
  //available brand assets
  availableAssetLogo: '',
  availableAssetBrandbook: '',
  availableAssetFonts: '',
  availableAssetColorPalettesInput: '',
  availableAssetmarketingCollateralInput: '',
  availableAssetBrochureInput: '',
  //5
  //competitors
  competitors1: '',
  competitors2: '',
  competitors3: '',
  //websites
  website1: '',
  website2: '',
  website3: '',
  usp: '',
  //6
  likeOrDislike: '',
  designAndFunction: '',
  pagesInWebsite: '',
  trafficMarketingOptions: '',
  //7
  landOnWebsite: '',
  howSuccess: '',
  aditionalInfo: '',
};
