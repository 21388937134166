import { useEffect, useState } from 'react';
import { Props } from '../interfaces';

const useInput = ({ formSelectProps, ...restProps }: Props) => {
  const [focused, setFocused] = useState(false);

  const handleFocusedBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    setFocused(true);
  };

  const handleFocusedFocus = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    restProps.name === 'confirmPassword' && setFocused(true);
  };

  useEffect(() => {
    if (!formSelectProps.isValidating) {
      setFocused(false);
    }
  }, [formSelectProps.isValidating]);

  return {
    focused,
    formSelectProps,
    restProps,
    handleFocusedBlur,
    handleFocusedFocus,
  };
};

export default useInput;
