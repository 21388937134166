import { Props } from '../interfaces';
import { useEffect, useState } from 'react';

const useInput = (DataListProps: Props) => {
  const { formInputProps, ...restProps } = DataListProps;

  const [focused, setFocused] = useState(false);

  const handleFocusedBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    setFocused(true);
  };

  const handleFocusedFocus = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    restProps.name === 'confirmPassword' && setFocused(true);
  };

  useEffect(() => {
    if (!formInputProps.isValidating) {
      setFocused(false);
    }
  }, [formInputProps.isValidating]);

  return {
    formInputProps,
    restProps,
    focused,
    handleFocusedBlur,
    handleFocusedFocus,
  };
};

export default useInput;
