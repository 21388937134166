import { createGlobalStyle } from 'styled-components';

const GlobalStyle: any = createGlobalStyle`

:root {

  /*
* =============== 
* Variables
* ===============
*/

/*
* Page Main color 
*/
  --color-white: #fff;
  --color-soft-white: #f2f2f2;


  --color-full-black: #07060A;
  --color-black: #100E16;
  --color-soft-black: #15131D;
  --color-soft-dark: #1C1926;
  --color-semiSoft-black: #13111A;
  --color-greyish-black: #393741;
  --color-inactive-grey: #707070;
  --color-grey: #707070;
  --color-dark-blue-cancel: #24222D;
  --color-text-purple: #6E6788;
  --color-purple: #38334A;
  --color-soft-purple: #454152;
  --color-orange: #EF6B36;
  --color-orange-red: #E94D1F;
  --color-mid-blue: #3E76BB;
  --color-light-blue: #719ED3;
  --color-green-success: #3BB94E;
  --color-red-fail: #E24126;


/*
* Trinkes 
*/
  --transition-fast: all 0.2s ease-in-out;
  --transition: all 0.3s ease-in-out;
  --transition-slow: all 0.6s ease-in-out;
  --transition-very-slow: all 0.9s ease-in-out;
  --spacing: 0.1rem;
  --subtitle-spacing: 3px;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --font-family: 'Montserrat', 'Helvetica', 'Helvetica Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell,
    'Open Sans',  sans-serif;
}

/*
* =============== 
* Global Styles
* ===============
*/

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


html,
body {
  height: 100%;
  background-color: var(--color-soft-black);
}

:root {
  font-family:'Helvetica Neue', 'Montserrat', 'Helvetica', -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell,
    'Open Sans',  sans-serif;
}

/* @media screen and (min-width: 280px) {
  :root {
    font-size: calc(14px + 8 * ((100vw - 280px) / 1640));
  }
  p {
    line-height: calc(19.6px + 11.2 * ((100vw - 280px) / 1640));
  }
}

@media screen and (min-width: 1920px) {
  :root {
    font-size: 20px;
  }

  p {
    line-height: 28px;
  }
} */

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4 {
  letter-spacing: var(--spacing);
  line-height: 1.25;
}

h1 {
  font-size: 2.79rem;
}

h2 {
  font-size: 1.8rem;
}

h3 {
  font-size: 1.25rem;
}

h4 {
  font-size: 0.875rem;
}

p {
  /* margin-bottom: 1.25rem; */
  color: var(--clr-grey-5);
}

/*
* ==================== 
* Custom Project Style
* ====================
*/

#root {
  min-height: 100%;

}

`;

export default GlobalStyle;
