import { ikusaName } from 'infrastructure/assets';

import * as S from './PageLogo.styled';

const PageLogo = () => {
  return (
    <S.PageLogo>
      <S.LogoImg src={ikusaName} alt='website logo' />
    </S.PageLogo>
  );
};

export default PageLogo;
