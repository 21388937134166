import styled, { css } from 'styled-components';
import { BsCheckCircleFill } from 'react-icons/bs';
import { TextAreaProps } from 'react-html-props';

export const FormTextArea = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;

  & div:focus {
    border: 1px solid var(--color-mid-blue);
    background-color: var(--color-greyish-black);
  }

  & div:active {
    background-color: var(--color-purple);
  }

  & div[disabled] {
    background-color: var(--color-grey);
    color: #625e5e;
    cursor: not-allowed;
  }

  & div[data-focused='true'] ~ span {
    display: block;
  }

  & div[data-focused='true'] {
    border: 1px solid #ef4848;
  }

  & div[data-pass='true'] {
    border: 1px solid #3bb94e;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: var(--color-soft-white);
    box-shadow: 0 0 0px 1000px var(--color-soft-black) inset;
    -webkit-box-shadow: 0 0 0px 1000px var(--color-soft-black) inset;
  }

  &:-webkit-autofill:focus {
    box-shadow: 0 0 0px 1000px var(--color-soft-black) inset;
    -webkit-box-shadow: 0 0 0px 1000px var(--color-greyish-black) inset;
  }
`;

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  margin-bottom: 10px;
  color: var(--color-soft-white);
  font-weight: 500;
`;

export const TextAreaCont = styled.div`
  position: relative;
  border-radius: 12px;
  background-color: var(--color-soft-purple);
  margin: 5px 0px 60px 0px;
  padding: 15px;
  transition: var(--transition);
  border: 1px solid transparent;

  @media screen and (min-width: 600px) {
    margin: 5px 0px 30px 0px;
  }
`;

export const TextArea = styled.textarea.attrs((props: TextAreaProps) => {
  return { ...props };
})`
  height: 100px;
  font-size: 16px;
  width: 100%;
  border: none;
  outline: none;
  resize: none;
  background-color: transparent;
  color: var(--color-soft-white);
  font-family: inherit;
  color-scheme: dark;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    padding: 10px 0px;
  }

  &::-webkit-scrollbar-track {
    background-color: #80808050;
    height: 5px;
    padding: 10px 0px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-grey);
    border-radius: 5px;
    height: 5px;
    padding: 10px 0px;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: var(--color-soft-white);
    box-shadow: 0 0 0px 1000px var(--color-soft-black) inset;
    -webkit-box-shadow: 0 0 0px 1000px var(--color-soft-black) inset;
  }

  &:-webkit-autofill:focus {
    box-shadow: 0 0 0px 1000px var(--color-soft-black) inset;
    -webkit-box-shadow: 0 0 0px 1000px var(--color-greyish-black) inset;
  }
`;

export const Error = styled.span`
  text-align: left;
  font-size: 16px;
  color: #ef4848;
  /* display: none; */
  position: absolute;
  bottom: 5px;
`;

interface IsCheckProps {
  pass: 'true' | 'false';
}

export const IsCheck = styled(BsCheckCircleFill)<IsCheckProps>`
  position: absolute;
  padding-top: 1px;
  top: 15px;
  right: 10px;
  transform: translate(-50%, 0%);
  color: #3bb94e;
  transition: var(--transition);
  ${({ pass }) =>
    pass === 'true'
      ? css`
          opacity: 1;
        `
      : css`
          opacity: 0;
        `}
`;
