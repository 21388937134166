import { useInput } from './hooks';
import { Props } from './interfaces';

import * as S from './FormDataList.styled';

const FormDataList = (DataListProps: Props) => {
  const { formInputProps, restProps, focused, handleFocusedBlur, handleFocusedFocus } =
    useInput(DataListProps);

  const { style, className, ...inputProps } = restProps;

  return (
    <S.FormDataList style={style} className={className}>
      {formInputProps.label ? (
        <S.Label htmlFor={inputProps.id}>{formInputProps.label}</S.Label>
      ) : null}

      <S.Input
        {...inputProps}
        list='countries'
        onBlur={handleFocusedBlur}
        onFocus={handleFocusedFocus}
        data-focused={formInputProps.errorMessage && focused ? true : false}
        data-pass={!formInputProps.errorMessage && inputProps.value ? true : false}
      />

      <S.DataList id='countries'>
        {formInputProps.options.map(({ name, code }) => (
          <S.Option key={code}>{name}</S.Option>
        ))}
      </S.DataList>
      {/* //!{!formInputProps.errorMessage && inputProps.value ? true : false} */}

      {formInputProps.withCheck ? (
        <S.IsCheck
          top={formInputProps.label ? 'true' : 'false'}
          pass={!formInputProps.errorMessage && inputProps.value ? 'true' : 'false'}
        />
      ) : null}

      <S.Span>{formInputProps.errorMessage}</S.Span>
    </S.FormDataList>
  );
};

export default FormDataList;
