import { isValidPhoneNumber } from 'libphonenumber-js';
import { FormErrorsProps, FormValueProps } from '../interfaces/interfaces';
import countries from './countries';

const validation = ({
  name,
  companyStartDate,
  companyEmail,
  phoneNumber,
  address1,
  address2,
  city,
  state,
  zipCode,
  country,
  staffAmount,
  briefCompanyHistory,
  missionVision,
  industrySector,
  productOrService,
  currentPositionInMarket,
  targetAudience,
  clientGeoLocation,
  availableAssetLogo,
  availableAssetBrandbook,
  availableAssetFonts,
  availableAssetColorPalettesInput,
  availableAssetmarketingCollateralInput,
  availableAssetBrochureInput,
  competitors1,
  competitors2,
  competitors3,
  website1,
  website2,
  website3,
  usp,
  likeOrDislike,
  designAndFunction,
  pagesInWebsite,
  trafficMarketingOptions,
  landOnWebsite,
  howSuccess,
  aditionalInfo,
}: FormValueProps) => {
  const errors: FormErrorsProps = {
    //1
    name: '',
    companyStartDate: '',
    companyEmail: '',
    phoneNumber: '',
    //2
    address1: '',
    address2: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
    //3
    staffAmount: '',
    briefCompanyHistory: '',
    missionVision: '',
    industrySector: '',
    productOrService: '',
    //4
    currentPositionInMarket: '',
    targetAudience: '',
    clientGeoLocation: '',
    //available brand assets
    availableAssetLogo: '',
    availableAssetBrandbook: '',
    availableAssetFonts: '',
    availableAssetColorPalettesInput: '',
    availableAssetmarketingCollateralInput: '',
    availableAssetBrochureInput: '',
    //5
    //competitors
    competitors1: '',
    competitors2: '',
    competitors3: '',
    //websites
    website1: '',
    website2: '',
    website3: '',
    usp: '',
    //6
    likeOrDislike: '',
    designAndFunction: '',
    pagesInWebsite: '',
    trafficMarketingOptions: '',
    //7
    landOnWebsite: '',
    howSuccess: '',
    aditionalInfo: '',
  };

  //Validation for name
  if (!name.trim()) {
    errors.name = 'This field is required';
  } else if (name.trim() && (name.trim().length < 3 || name.trim().length > 26)) {
    errors.name = 'Only 3 to 26 characters allowed';
  } else if (name.trim() && !/^[a-zA-Z ]*$/.test(name.trim())) {
    errors.name = 'Number or special characters are not allowed';
  } else {
    errors.name = '';
  }

  //Validation for email
  if (!companyEmail.trim()) {
    errors.companyEmail = 'This field is required';
  } else if (
    companyEmail.trim() &&
    !companyEmail
      .trim()
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  ) {
    errors.companyEmail = 'Please use a valid email address';
  } else {
    errors.companyEmail = '';
  }

  //phoneNumber
  if (!phoneNumber.trim()) {
    errors.phoneNumber = 'This field is required';
  } else if (phoneNumber.trim() && !isValidPhoneNumber(phoneNumber)) {
    errors.phoneNumber = 'Please use a valid phone number';
  } else {
    errors.phoneNumber = '';
  }

  //address 1
  if (!address1.trim()) {
    errors.address1 = 'This field is required';
  } else if (address1.trim() && address1.trim().length < 3) {
    errors.address1 = 'Use at least 3 characters';
  } else {
    errors.address1 = '';
  }

  //address 2
  if (address2.trim() && address2.trim().length < 3) {
    errors.address2 = 'Use at least 3 characters';
  } else {
    errors.address2 = '';
  }

  //city
  if (city.trim() && city.trim().length < 3) {
    errors.city = 'Use at least 3 characters';
  } else {
    errors.city = '';
  }

  //state
  if (state.trim() && state.trim().length < 3) {
    errors.state = 'Use at least 3 characters';
  } else {
    errors.state = '';
  }

  //zipcode
  if (zipCode.trim() && !/^[0-9\- ]*$/.test(zipCode.trim())) {
    errors.zipCode = 'Only number and hyphens allowed';
  } else {
    errors.zipCode = '';
  }

  //country
  if (!country.trim()) {
    errors.country = 'This field is required';
  } else if (
    !!!countries.find(
      (item) => item.name === country.replace(/\b[a-z]/g, (char) => char.toUpperCase())
    )
  ) {
    errors.country = 'Please pick a valid country name';
  } else {
    errors.country = '';
  }

  //staff amount
  if (!staffAmount.trim()) {
    errors.staffAmount = 'This field is required';
  } else {
    errors.staffAmount = '';
  }

  //staff amount
  if (!staffAmount.trim()) {
    errors.staffAmount = 'This field is required';
  } else {
    errors.staffAmount = '';
  }

  //company history
  if (!briefCompanyHistory.trim()) {
    errors.briefCompanyHistory = 'This field is required';
  } else {
    errors.briefCompanyHistory = '';
  }

  //mission vision
  if (!missionVision.trim()) {
    errors.missionVision = 'This field is required';
  } else {
    errors.missionVision = '';
  }

  //industry sector
  if (!industrySector.trim()) {
    errors.industrySector = 'This field is required';
  } else {
    errors.industrySector = '';
  }

  //product or service
  if (!productOrService.trim()) {
    errors.productOrService = 'This field is required';
  } else {
    errors.productOrService = '';
  }

  //current position in market
  if (!currentPositionInMarket.trim()) {
    errors.currentPositionInMarket = 'This field is required';
  } else {
    errors.currentPositionInMarket = '';
  }

  //target audience
  if (!targetAudience.trim()) {
    errors.targetAudience = 'This field is required';
  } else {
    errors.targetAudience = '';
  }

  //geographically
  if (!clientGeoLocation.trim()) {
    errors.clientGeoLocation = 'This field is required';
  } else {
    errors.clientGeoLocation = '';
  }

  //competitors1
  if (!competitors1.trim()) {
    errors.competitors1 = 'This field is required';
  } else {
    errors.competitors1 = '';
  }

  if (!website1.trim()) {
    errors.website1 = 'This field is required';
  } else {
    errors.website1 = '';
  }

  if (!usp.trim()) {
    errors.usp = 'This field is required';
  } else {
    errors.usp = '';
  }

  if (!likeOrDislike.trim()) {
    errors.likeOrDislike = 'This field is required';
  } else {
    errors.likeOrDislike = '';
  }

  if (!designAndFunction.trim()) {
    errors.designAndFunction = 'This field is required';
  } else {
    errors.designAndFunction = '';
  }

  if (!pagesInWebsite.trim()) {
    errors.pagesInWebsite = 'This field is required';
  } else {
    errors.pagesInWebsite = '';
  }

  if (!trafficMarketingOptions.trim()) {
    errors.trafficMarketingOptions = 'This field is required';
  } else {
    errors.trafficMarketingOptions = '';
  }

  if (!landOnWebsite.trim()) {
    errors.landOnWebsite = 'This field is required';
  } else {
    errors.landOnWebsite = '';
  }

  if (!howSuccess.trim()) {
    errors.howSuccess = 'This field is required';
  } else {
    errors.howSuccess = '';
  }

  return errors;
};

export default validation;
