import styled, { css } from 'styled-components';
import { BsCheckCircleFill } from 'react-icons/bs';

export const FormPhoneInputCont = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
`;

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
  color: var(--color-soft-white);
`;

export const Span = styled.span`
  position: absolute;
  font-size: 16px;
  padding: 3px;
  color: #ef4848;
  bottom: 0px;
`;

interface IsCheckProps {
  pass: 'true' | 'false';
  top?: 'true' | 'false';
}

export const IsCheck = styled(BsCheckCircleFill)<IsCheckProps>`
  position: absolute;
  padding-top: 1px;
  top: ${({ top }) => (top === 'true' ? '50%' : '33%')};
  right: 10px;
  font-size: 18px;
  transform: translate(-50%, -50%);
  color: #3bb94e;
  transition: var(--transition);
  ${({ pass }) =>
    pass === 'true'
      ? css`
          opacity: 1;
        `
      : css`
          opacity: 0;
        `}
`;
