import { useId } from 'react';
import {
  FormDataList,
  FormInput,
  FormPhoneInput,
  FormSelect,
  FormTextArea,
  RegularButton,
} from 'infrastructure/components';
import { countries, formErrors, formValues, staffAmount } from './utils';
import { useForm } from './hooks';

import * as S from './BriefForm.styled';
import { BeatLoader } from 'react-spinners';

const SignInForm = () => {
  const inputId = useId();

  const {
    values,
    errors,
    disableSubmit,
    isValidating,
    isLoading,
    handleSubmit,
    phoneNumberHandleChange,
    inputHandleChange,
    selectHandleChange,
    textAreaHandleChange,
  } = useForm(formValues, formErrors);

  return (
    <S.Form onSubmit={handleSubmit}>
      <S.FieldSet>
        <S.FieldLegend>Basic Information</S.FieldLegend>
        <S.InputsContainer>
          <FormInput
            id={`${inputId}-companyNameInput`}
            name='name'
            type='text'
            placeholder='Company'
            onChange={inputHandleChange}
            value={values.name}
            required
            formInputProps={{
              label: 'Company name *',
              errorMessage: errors.name,
              withCheck: true,
              isValidating,
            }}
          />
          <FormInput
            id={`${inputId}-dateFoundedInput`}
            name='companyStartDate'
            type='date'
            onChange={inputHandleChange}
            value={values.companyStartDate}
            formInputProps={{
              errorMessage: errors.companyStartDate,
              label: 'Date Established',
              withCheck: false,
              isValidating,
            }}
          />
        </S.InputsContainer>
        <S.InputsContainer>
          <FormInput
            id={`${inputId}-emailInput`}
            name='companyEmail'
            type='email'
            placeholder='email@email.com'
            onChange={inputHandleChange}
            value={values.companyEmail}
            required
            formInputProps={{
              label: 'Email *',
              errorMessage: errors.companyEmail,
              withCheck: true,
              isValidating,
            }}
          />
          <FormPhoneInput
            id={`${inputId}-phoneInput`}
            name='phoneNumber'
            placeholder='1 (234) 567-8910'
            formInputProps={{
              label: 'Phone number *',
              errorMessage: errors.phoneNumber,
              withCheck: true,
              onChange: phoneNumberHandleChange,
              value: values.phoneNumber || '',
              isValidating,
            }}
          />
        </S.InputsContainer>
      </S.FieldSet>
      <S.FieldSet>
        <S.FieldLegend>Location Information</S.FieldLegend>
        <FormInput
          id={`${inputId}-address1Input`}
          name='address1'
          type='text'
          placeholder='Address Line 1'
          onChange={inputHandleChange}
          value={values.address1}
          formInputProps={{
            label: 'Address 1 *',
            errorMessage: errors.address1,
            withCheck: true,
            isValidating,
          }}
        />
        <FormInput
          id={`${inputId}-address2Input`}
          name='address2'
          type='text'
          placeholder='Address Line 2'
          onChange={inputHandleChange}
          value={values.address2}
          formInputProps={{
            label: 'Address 2',
            errorMessage: errors.address2,
            withCheck: true,
            isValidating,
          }}
        />
        <S.InputsContainer>
          <FormInput
            id={`${inputId}-cityInput`}
            name='city'
            type='text'
            placeholder='Barcelona'
            onChange={inputHandleChange}
            value={values.city}
            formInputProps={{
              label: 'City',
              errorMessage: errors.city,
              withCheck: true,
              isValidating,
            }}
          />
          <FormInput
            id={`${inputId}-stateInput`}
            name='state'
            type='text'
            placeholder='Anzoátegui'
            onChange={inputHandleChange}
            value={values.state}
            formInputProps={{
              label: 'State',
              errorMessage: errors.state,
              withCheck: true,
              isValidating,
            }}
          />
        </S.InputsContainer>
        <S.InputsContainer>
          <FormInput
            id={`${inputId}-zipCodeInput`}
            name='zipCode'
            type='text'
            placeholder='0230'
            onChange={inputHandleChange}
            value={values.zipCode}
            formInputProps={{
              label: 'Zip Code',
              errorMessage: errors.zipCode,
              withCheck: false,
              isValidating,
            }}
          />

          <FormDataList
            id={`${inputId}-countryInput`}
            name='country'
            type='text'
            placeholder='Venezuela (VE)'
            onChange={inputHandleChange}
            value={values.country}
            required
            formInputProps={{
              options: countries,
              label: 'Country *',
              errorMessage: errors.country,
              withCheck: true,
              isValidating,
            }}
          />
        </S.InputsContainer>
      </S.FieldSet>
      <S.Division />
      <S.FieldSet>
        <S.FieldLegend>Web Brief Information</S.FieldLegend>

        <FormSelect
          id={`${inputId}-staffAmountInput`}
          name='staffAmount'
          onChange={selectHandleChange}
          value={values.staffAmount}
          required
          formSelectProps={{
            errorMessage: errors.staffAmount,
            label: 'How many staff work in the company? *',
            options: staffAmount,
            withCheck: false,
            isValidating,
          }}
        />

        <FormTextArea
          id={`${inputId}-briefCompanyHistoryInput`}
          name='briefCompanyHistory'
          onChange={textAreaHandleChange}
          value={values.briefCompanyHistory}
          required
          formTextAreaProps={{
            errorMessage: errors.briefCompanyHistory,
            label: 'Brief history of the  company *',
            withCheck: true,
            isValidating,
          }}
        />

        <FormTextArea
          id={`${inputId}-missionInput`}
          name='missionVision'
          onChange={textAreaHandleChange}
          value={values.missionVision}
          required
          formTextAreaProps={{
            errorMessage: errors.missionVision,
            label: 'Mission & Vision *',
            withCheck: true,
            isValidating,
          }}
        />

        <FormTextArea
          id={`${inputId}-industrySectorInput`}
          name='industrySector'
          onChange={textAreaHandleChange}
          value={values.industrySector}
          required
          formTextAreaProps={{
            errorMessage: errors.industrySector,
            label: 'What specific industry do you work in? *',
            withCheck: true,
            isValidating,
          }}
        />

        <FormTextArea
          id={`${inputId}-productOrServiceInput`}
          name='productOrService'
          onChange={textAreaHandleChange}
          value={values.productOrService}
          required
          formTextAreaProps={{
            errorMessage: errors.productOrService,
            label: 'What products or services does your company currently offer? *',
            withCheck: true,
            isValidating,
          }}
        />
      </S.FieldSet>
      <S.Division />
      <S.FieldSet>
        <S.FieldLegend>Web Brief Information</S.FieldLegend>
        <FormTextArea
          id={`${inputId}-currentPositionInMarketInput`}
          name='currentPositionInMarket'
          onChange={textAreaHandleChange}
          value={values.currentPositionInMarket}
          required
          formTextAreaProps={{
            errorMessage: errors.currentPositionInMarket,
            label:
              'Current position in the market is: for example, are you providing a premium, middle or low-cost product / service? *',
            withCheck: true,
            isValidating,
          }}
        />
        <FormTextArea
          id={`${inputId}-targetAudienceInput`}
          name='targetAudience'
          onChange={textAreaHandleChange}
          value={values.targetAudience}
          required
          formTextAreaProps={{
            errorMessage: errors.targetAudience,
            label: 'Target Audience *',
            withCheck: true,
            isValidating,
          }}
        />

        <FormTextArea
          id={`${inputId}-clientGeoLocationInput`}
          name='clientGeoLocation'
          onChange={textAreaHandleChange}
          value={values.clientGeoLocation}
          required
          formTextAreaProps={{
            errorMessage: errors.clientGeoLocation,
            label:
              'Geographically, where are your customers based? Are they local to you? Are they nationwide, or even global? *',
            withCheck: true,
            isValidating,
          }}
        />
        <S.Label>What are the following brand assets available</S.Label>
        <S.CheckBoxCont>
          <S.InputsContainer>
            <FormInput
              id={`${inputId}-availableAssetLogoInput`}
              name='availableAssetLogo'
              type='checkbox'
              onChange={inputHandleChange}
              value={values.availableAssetLogo}
              formInputProps={{
                label: 'Logo',
                errorMessage: errors.availableAssetLogo,
                withCheck: false,
                isValidating,
              }}
            />
            <FormInput
              id={`${inputId}-availableAssetBrandbookInput`}
              name='availableAssetBrandbook'
              type='checkbox'
              onChange={inputHandleChange}
              value={values.availableAssetBrandbook}
              formInputProps={{
                label: 'Brandbook',
                errorMessage: errors.availableAssetBrandbook,
                withCheck: false,
                isValidating,
              }}
            />
            <FormInput
              id={`${inputId}-availableAssetFontsInput`}
              name='availableAssetFonts'
              type='checkbox'
              onChange={inputHandleChange}
              value={values.availableAssetFonts}
              formInputProps={{
                label: 'Fonts',
                errorMessage: errors.availableAssetFonts,
                withCheck: false,
                isValidating,
              }}
            />
          </S.InputsContainer>
          <S.InputsContainer>
            <FormInput
              id={`${inputId}-availableAssetColorPalettesInput`}
              name='availableAssetColorPalettesInput'
              type='checkbox'
              placeholder='0230'
              onChange={inputHandleChange}
              value={values.availableAssetColorPalettesInput}
              formInputProps={{
                label: 'Color Palette',
                errorMessage: errors.availableAssetColorPalettesInput,
                withCheck: false,
                isValidating,
              }}
            />
            <FormInput
              id={`${inputId}-availableAssetmarketingCollateralInput`}
              name='availableAssetmarketingCollateralInput'
              type='checkbox'
              placeholder='0230'
              onChange={inputHandleChange}
              value={values.availableAssetmarketingCollateralInput}
              formInputProps={{
                label: 'Marketing collateral',
                errorMessage: errors.availableAssetmarketingCollateralInput,
                withCheck: false,
                isValidating,
              }}
            />
            <FormInput
              id={`${inputId}-availableAssetBrochureInput`}
              name='availableAssetBrochureInput'
              type='checkbox'
              placeholder='0230'
              onChange={inputHandleChange}
              value={values.availableAssetBrochureInput}
              formInputProps={{
                label: 'Brochure',
                errorMessage: errors.availableAssetBrochureInput,
                withCheck: false,
                isValidating,
              }}
            />
          </S.InputsContainer>
        </S.CheckBoxCont>
      </S.FieldSet>
      <S.Division />
      <S.FieldSet>
        <S.FieldLegend>Web Brief Information</S.FieldLegend>
        <S.Label>Please name 3 main competitors:</S.Label>
        <FormInput
          id={`${inputId}-competitors1Input`}
          name='competitors1'
          type='text'
          placeholder='Business name *'
          onChange={inputHandleChange}
          value={values.competitors1}
          formInputProps={{
            errorMessage: errors.competitors1,
            withCheck: true,
            isValidating,
          }}
        />
        <FormInput
          id={`${inputId}-competitors2Input`}
          name='competitors2'
          type='text'
          placeholder='Business name'
          onChange={inputHandleChange}
          value={values.competitors2}
          formInputProps={{
            errorMessage: errors.competitors2,
            withCheck: true,
            isValidating,
          }}
        />
        <FormInput
          id={`${inputId}-competitors1Input`}
          name='competitors3'
          type='text'
          placeholder='Business name'
          onChange={inputHandleChange}
          value={values.competitors3}
          formInputProps={{
            errorMessage: errors.competitors3,
            withCheck: true,
            isValidating,
          }}
        />
        <S.Label>
          Reference websites – provide 3 examples of websites you either like or those of your
          competitors.
        </S.Label>
        <FormInput
          id={`${inputId}-website1Input`}
          name='website1'
          type='text'
          placeholder='Website url *'
          onChange={inputHandleChange}
          value={values.website1}
          formInputProps={{
            errorMessage: errors.website1,
            withCheck: true,
            isValidating,
          }}
        />
        <FormInput
          id={`${inputId}-website2Input`}
          name='website2'
          type='text'
          placeholder='Website url'
          onChange={inputHandleChange}
          value={values.website2}
          formInputProps={{
            errorMessage: errors.website2,
            withCheck: true,
            isValidating,
          }}
        />
        <FormInput
          id={`${inputId}-website3Input`}
          name='website3'
          type='text'
          placeholder='Website url'
          onChange={inputHandleChange}
          value={values.website3}
          formInputProps={{
            errorMessage: errors.website3,
            withCheck: true,
            isValidating,
          }}
        />
        <FormTextArea
          id={`${inputId}-uspInput`}
          name='usp'
          onChange={textAreaHandleChange}
          value={values.usp}
          required
          formTextAreaProps={{
            errorMessage: errors.usp,
            label:
              'What are your USPs Unique Selling Proposition? What sets you apart from the competition? *',
            withCheck: true,
            isValidating,
          }}
        />
      </S.FieldSet>
      <S.Division />
      <S.FieldSet>
        <S.FieldLegend>Web Brief Information</S.FieldLegend>
        <FormTextArea
          id={`${inputId}-likeOrDislikeInput`}
          name='likeOrDislike'
          onChange={textAreaHandleChange}
          value={values.likeOrDislike}
          required
          formTextAreaProps={{
            errorMessage: errors.likeOrDislike,
            label: 'Name a few things you like and dislike about your existing website: *',
            withCheck: true,
            isValidating,
          }}
        />
        <FormTextArea
          id={`${inputId}-designAndFunctionInput`}
          name='designAndFunction'
          onChange={textAreaHandleChange}
          value={values.designAndFunction}
          required
          formTextAreaProps={{
            errorMessage: errors.designAndFunction,
            label:
              'What design and function requirements would you like for the website? For example, live chat, contact form, location map. *',
            withCheck: true,
            isValidating,
          }}
        />
        <FormTextArea
          id={`${inputId}-pagesInWebsiteInput`}
          name='pagesInWebsite'
          onChange={textAreaHandleChange}
          value={values.pagesInWebsite}
          required
          formTextAreaProps={{
            errorMessage: errors.pagesInWebsite,
            label:
              'What pages would you like in your website? For example: Home, about us, services, industries, contact us? *',
            withCheck: true,
            isValidating,
          }}
        />
        <FormTextArea
          id={`${inputId}-trafficMarketingOptionsInput`}
          name='trafficMarketingOptions'
          onChange={textAreaHandleChange}
          value={values.trafficMarketingOptions}
          required
          formTextAreaProps={{
            errorMessage: errors.trafficMarketingOptions,
            label:
              'Will you be using email marketing, paid Ads, social media to get traffic into your website? *',
            withCheck: true,
            isValidating,
          }}
        />
      </S.FieldSet>
      <S.Division />
      <S.FieldSet>
        <S.FieldLegend>Web Brief Information</S.FieldLegend>
        <FormTextArea
          id={`${inputId}-landOnWebsiteInput`}
          name='landOnWebsite'
          onChange={textAreaHandleChange}
          value={values.landOnWebsite}
          required
          formTextAreaProps={{
            errorMessage: errors.landOnWebsite,
            label:
              'When they land on your website, what do you want your customers to do? Maybe you want them to subscribe to a service or make an enquiry. List out the thing(s) you want the customer to do: *',
            withCheck: true,
            isValidating,
          }}
        />
        <FormTextArea
          id={`${inputId}-howSuccessInput`}
          name='howSuccess'
          onChange={textAreaHandleChange}
          value={values.howSuccess}
          required
          formTextAreaProps={{
            errorMessage: errors.howSuccess,
            label: 'What needs to be achieved for you to consider the website a success? *',
            withCheck: true,
            isValidating,
          }}
        />
        <FormTextArea
          id={`${inputId}-aditionalInfoInput`}
          name='aditionalInfo'
          onChange={textAreaHandleChange}
          value={values.aditionalInfo}
          formTextAreaProps={{
            errorMessage: errors.aditionalInfo,
            label: 'Additional information',
            withCheck: true,
            isValidating,
          }}
        />
      </S.FieldSet>

      {isLoading ? (
        <S.SpinnerCont>
          <BeatLoader color='#ffffff50' margin={3} speedMultiplier={0.6} />
        </S.SpinnerCont>
      ) : (
        <RegularButton
          regularButtonProps={{ color: 'orange', text: 'SEND' }}
          disabled={disableSubmit}
          type='submit'
        />
      )}
    </S.Form>
  );
};

export default SignInForm;
