import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import GlobalStyle from 'globalStyle';

createRoot(document.getElementById('root') as HTMLElement, {
  identifierPrefix: 'id-',
}).render(
  <React.StrictMode>
    <GlobalStyle />
    <App />
  </React.StrictMode>
);
