import { useTextArea } from './hooks';
import { Props } from './interfaces';

import * as S from './FormTextArea.styled';

const FormTextArea = (textAreaProps: Props) => {
  const { formTextAreaProps, restProps, focused, handleFocusedBlur, handleFocusedFocus } =
    useTextArea(textAreaProps);

  return (
    <S.FormTextArea className={restProps.className} style={restProps.style}>
      <S.Label htmlFor={restProps.id}>{formTextAreaProps.label}</S.Label>
      <S.TextAreaCont
        data-focused={formTextAreaProps.errorMessage && focused}
        data-pass={!formTextAreaProps.errorMessage && restProps.value ? true : false}
        tabIndex={0}
      >
        <S.TextArea {...restProps} onBlur={handleFocusedBlur} onFocus={handleFocusedFocus} />
        {formTextAreaProps.withCheck ? (
          <S.IsCheck pass={!formTextAreaProps.errorMessage && restProps.value ? 'true' : 'false'} />
        ) : null}
      </S.TextAreaCont>
      <S.Error>{formTextAreaProps.errorMessage}</S.Error>
    </S.FormTextArea>
  );
};

export default FormTextArea;
