import styled from 'styled-components';

export const Home = styled.main`
  display: grid;
  grid:
    [row1-start] 'header' 60px [row1-end]
    [row2-start] 'page' calc(100vh - 60px) [row2-end]
    / auto;
  min-height: 100%;
  max-width: 1920px;
  margin: 0 auto;
  background-color: var(--color-black);
`;

export const ContentCont = styled.div`
  grid-area: page;
  min-height: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
`;
