import { useEffect, useState } from 'react';
import { Props } from '../interfaces';

const useInput = ({ formInputProps, ...restProps }: Props) => {
  const [focused, setFocused] = useState(false);
  const { onChange, value, ...cleanRestProps } = restProps;

  const handleFocusedBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    setFocused(true);
  };

  const handleFocusedFocus = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    restProps.name === 'confirmPassword' && setFocused(true);
  };

  useEffect(() => {
    if (!formInputProps.isValidating) {
      setFocused(false);
    }
  }, [formInputProps.isValidating]);

  return {
    focused,
    formInputProps,
    cleanRestProps,
    handleFocusedBlur,
    handleFocusedFocus,
  };
};

export default useInput;
