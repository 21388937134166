import { BriefForm, Footer, Header } from 'infrastructure/components';

import * as S from './Home.styled';

const Home = () => {
  return (
    <S.Home data-asd='asdasd'>
      <Header />
      <S.ContentCont>
        <BriefForm />
        <Footer />
      </S.ContentCont>
    </S.Home>
  );
};

export default Home;
