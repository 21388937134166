import styled from 'styled-components';

export const PageLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
`;

export const LogoImg = styled.img`
  max-height: 100%;

  height: 14px;

  @media screen and (min-width: 600px) {
    height: 16px;
  }

  @media screen and (min-width: 900px) {
    height: 18px;
  }

  @media screen and (min-width: 1200px) {
    height: 20px;
  }
`;
