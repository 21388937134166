import * as S from './RegularButton.styled';
import { Props, RegularButtonProps } from './interfaces';

const RegularButton = ({ regularButtonProps, ...restProps }: Props) => {
  let { color, text, fullWidth }: RegularButtonProps = regularButtonProps || {
    text: 'bottom',
    color: 'blue',
    fullWidth: false,
  };

  return (
    <S.RegularButton color={color} fullWidth={fullWidth} {...restProps}>
      {text}
    </S.RegularButton>
  );
};

export default RegularButton;
