import styled, { css } from 'styled-components';
import { FiInstagram } from 'react-icons/fi';
import { TiSocialFacebook } from 'react-icons/ti';
import { AiOutlineCopyright } from 'react-icons/ai';

export const Footer = styled.footer`
  grid-area: footer;
  background-color: var(--color-soft-black);
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: auto;
  scroll-snap-align: start;

  @media screen and (min-width: 600px) {
    flex-direction: row;
  }

  @media screen and (min-width: 900px) {
    min-height: 300px;
    padding: 0px 40px;
  }

  @media screen and (min-width: 1200px) {
    min-height: 300px;
    padding: 0px 80px;
  }
`;

export const Footer__leftCont = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  //!borrar margin top
  margin-top: 50px;
  margin-bottom: 50px;

  @media screen and (min-width: 600px) {
    align-items: flex-start;
    padding: 0 0 0 60px;
  }

  @media screen and (min-width: 900px) {
    align-items: flex-start;
    margin: 0;
    padding: 0;
    width: fit-content;
  }
`;

export const Footer__logo = styled.img`
  max-width: 170px;
  margin-bottom: 20px;
  height: auto;

  @media screen and (min-width: 900px) {
    margin-bottom: 15px;
    max-width: 100%;
  }
`;

export const Footer__socialLinks = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: min(90%, 90px);
  margin: 20px 0;

  @media screen and (min-width: 600px) {
    margin: 20px 0 20px 0;
    width: fit-content;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
  }

  @media screen and (min-width: 900px) {
    margin: 20px 0 20px 0;
    width: fit-content;
    justify-content: center;
    flex-direction: column;
  }
`;

export const Footer__socialLink = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (min-width: 600px) {
    margin-bottom: 10px;
  }
`;

export const Instagramlogo = styled(FiInstagram)`
  font-size: 20px;
  margin: 10px;
  color: var(--color-orange);

  @media screen and (min-width: 600px) {
    margin: 0 10px 0 0;
  }
`;

export const Facebooklogo = styled(TiSocialFacebook)`
  font-size: 20px;
  margin: 10px;
  color: var(--color-soft-black);
  background-color: var(--color-orange);
  border-radius: 50%;

  @media screen and (min-width: 600px) {
    margin: 0 10px 0 0;
  }
`;

export const Copyright = styled(AiOutlineCopyright)`
  font-size: 20px;
  margin-right: 10px;
  color: var(--color-soft-white);

  @media screen and (min-width: 600px) {
    color: var(--color-orange);
  }
`;

export const SocialLinks__text = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface textInfoProps {
  inSocial?: boolean;
}

export const TextInfo = styled.span<textInfoProps>`
  font-size: 14px;
  text-shadow: 0px 0px 0.5px #f2f2f280;
  color: #f2f2f280;

  ${({ inSocial }) =>
    inSocial &&
    css`
      display: none;
    `}

  @media screen and (min-width: 600px) {
    ${({ inSocial }) =>
      inSocial &&
      css`
        display: block;
      `}
  }
`;

export const Footer__rightCont = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: min(100%, 800px);
  padding: 80px 20px 20px 20px;

  @media screen and (min-width: 600px) {
    align-items: flex-end;
    padding: 60px 60px 0 0;
  }

  @media screen and (min-width: 900px) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0 20px;
  }
`;
